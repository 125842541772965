import { useNxTranslation } from "@nexthink/apollo-components";
import { initProductShellContext } from "@nexthink/product-shell-library";
import { type FC, useEffect, useMemo } from "react";
import { Navigation } from "../Navigation/Navigation";
import { SEARCH_APP_ID } from "../Navigation/constants";
import ProductShellRouter from "../ProductShellRouter/ProductShellRouter";
import { useAuthContext } from "../context/AuthContext";
import { useMenuContext } from "../context/MenuContext";
import { usePortalContext } from "../context/PortalContext";
import { useProductShellContext } from "../context/ProductShellContext";
import { UserProvider } from "../context/UserContext";
import DeviceLocatorDialog, { DEVICE_LOCATOR_DIALOG_ID } from "../dialogs/DeviceLocatorDialog/DeviceLocatorDialog";
import DownloadFinderDialog, { DOWNLOAD_FINDER_DIALOG_ID } from "../dialogs/DownloadFinderDialog/DownloadFinderDialog";
import SearchAppDialog, { SEARCH_APP_DIALOG_ID } from "../dialogs/SearchAppDialog/SearchAppDialog";
import ErrorPage from "../errors/ErrorPage/ErrorPage";
import { initializeEventDrivenBuses } from "../eventDrivenBusEvents";
import usePendo from "../hooks/usePendo";
import usePortalService from "../hooks/usePortalService";
import useProductShellService from "../hooks/useProductShellService";
import useRefreshDynamicMenu from "../hooks/useRefreshDynamicMenu";
import { useRefreshServiceConfig } from "../hooks/useRefreshServiceConfig";
import useSendToAmplify from "../hooks/useSendToAmplify";
import type { MenuResponse } from "../services/types/menu";
import type { PortalMenuResponse } from "../services/types/portal-menu";
import { isSearchAppPresent } from "../utils";
import { StyledMenu, StyledPSContentContainer } from "./MainContainer.style";

export const MAIN_CONTENT_ID = "product-shell-content";
const MAIN_CONTAINER_TRANSLATION_NAMESPACE = "main-container";

const MainContainer: FC = () => {
  usePendo();
  const { openedDialogId, closeDialog, serviceConfig, serviceError } = useProductShellContext();
  const { menu, setMenu } = useMenuContext();
  const { isAuthenticated, getToken, hasPortal } = useAuthContext();
  const { t } = useNxTranslation();
  const { version } = usePortalContext();
  const { getPortalMenus } = usePortalService();
  const { getProductShellStaticMenus } = useProductShellService();
  const { refreshDynamicMenu } = useRefreshDynamicMenu();
  const { refreshServiceConfig } = useRefreshServiceConfig();
  useSendToAmplify();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isAuthenticated) {
      initProductShellContext();
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: exclude functions that are not used in the effect to avoid duplicate calls to PSS
  useEffect(() => {
    const canInitialize = isAuthenticated && !menu && (!hasPortal || version);

    if (canInitialize) {
      const getInitialDataFn = async () => {
        let portalMenuResponse: PortalMenuResponse | null = null;
        if (hasPortal) {
          [portalMenuResponse] = await Promise.all([getPortalMenus(), getToken()]);
        }
        const productShellMenuResponse = await getProductShellMenuAndRefreshServiceConfig(portalMenuResponse);

        setMenu(productShellMenuResponse);

        initializeEventDrivenBuses({
          commandHandlers: productShellMenuResponse?.commandHandlers || {},
          eventSubscribers: productShellMenuResponse?.eventSubscribers || {},
        });

        await refreshDynamicMenus(productShellMenuResponse);
      };

      getInitialDataFn();
    }
  }, [isAuthenticated, hasPortal, menu, version]);

  const getProductShellMenuAndRefreshServiceConfig = async (portalMenuResponse: PortalMenuResponse | null) => {
    const [productShellMenuResponse] = await Promise.all([
      getProductShellStaticMenus(portalMenuResponse),
      refreshServiceConfig(),
    ]);
    return productShellMenuResponse;
  };

  const refreshDynamicMenus = async (productShellMenuResponse: MenuResponse | null) => {
    const menus = productShellMenuResponse?.groupedMenus.flatMap((group) => group.menuItems);

    if (menus) {
      for (const m of menus) {
        if (m.apiUrl) {
          refreshDynamicMenu(m.id);
        }
      }
    }
  };

  const modules = useMemo(() => {
    return menu?.apps.filter((app) => app.id !== SEARCH_APP_ID) || [];
  }, [menu?.apps]);

  const searchComponent =
    menu && isSearchAppPresent(menu) ? (
      <SearchAppDialog isOpen={openedDialogId === SEARCH_APP_DIALOG_ID} onClose={closeDialog} />
    ) : null;

  const renderMainContent = () => {
    // menu should be null here but typescript doesn't know about it

    return (
      <UserProvider>
        <StyledMenu id="product-shell-menu">
          <Navigation />
        </StyledMenu>
        <StyledPSContentContainer
          id={MAIN_CONTENT_ID}
          aria-label={t("mainContent", { ns: MAIN_CONTAINER_TRANSLATION_NAMESPACE })}
        >
          <ProductShellRouter modules={modules} />
          <DeviceLocatorDialog isOpen={openedDialogId === DEVICE_LOCATOR_DIALOG_ID} onClose={closeDialog} />
          <DownloadFinderDialog isOpen={openedDialogId === DOWNLOAD_FINDER_DIALOG_ID} onClose={closeDialog} />
          {searchComponent}
        </StyledPSContentContainer>
      </UserProvider>
    );
  };

  const renderPlaceholder = () => (serviceError ? <ErrorPage id="product-shell-service-error" /> : null);

  return <>{menu && serviceConfig && isAuthenticated ? renderMainContent() : renderPlaceholder()}</>;
};

export default MainContainer;
