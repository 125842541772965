import type { ProductShellState } from "@nexthink/product-shell-library";
import { isEqual } from "lodash";
import { type FC, memo, useEffect } from "react";
import { Redirect, Route, type RouteComponentProps, Switch, useLocation } from "react-router-dom";
import LandingPageNoMenu from "../LandingPageNoMenu/LandingPageNoMenu";
import PortalWrapper from "../PortalWrapper";
import { useMenuContext } from "../context/MenuContext";
import { useProductShellContext } from "../context/ProductShellContext";
import ErrorNotFound from "../errors/ErrorNotFound/ErrorNotFound";
import ErrorPage from "../errors/ErrorPage/ErrorPage";
import { eventDrivenBusesForMFE } from "../eventDrivenBusEvents";
import { useDefaultRoute } from "../hooks/useDefaultRoute";
import { useGetApplyCacheBustingToUrl } from "../hooks/useGetApplyCacheBustingToUrl";
import { useGetDynamicComponentProps } from "../hooks/useGetDynamicComponentProps";
import { useHandleDialogRoutes } from "../hooks/useHandleDialogRoutes";
import InnerFederatedComponentPure from "../moduleFederation/InnerFederatedComponentPure";
import { dynamicComponentData } from "../moduleFederation/__mocks__/appearancePageFederationConfig";
import { useIsModuleFederationEnabled } from "../moduleFederation/useInitialisedModuleFederation";
import { observabilityForMicroFrontEnd, observeMicroFrontEnd } from "../observability";
import type { App } from "../services/types/menu";
import {
  customDashboardsHashUrlToUrl,
  filterOutDialogMenus,
  getAppStateType,
  getErrorCode,
  getErrorMessage,
  getPropsAreEqual,
} from "../utils";
import DynamicComponent from "./DynamicComponent";
import LoadingOverlay from "./LoadingOverlay";

export const PORTAL_APP = "PortalWrapper";

// TODO publish it in a package <------ STILL NECESSARY FOR NXSM
// https://jira.nexthink.com:1443/browse/FLASH-628
export interface PortalContext {
  firstDate: number;
  lastDate: number;
  scope: string;
  hierarchyId: number;
  hierarchyPath: string[];
}

export interface ProductShellRouterProps {
  modules: App[];
}

export interface InternalComponentProps extends RouteComponentProps {
  id: string;
  jsFile: string;
  cssFiles?: string[];
  jsResources?: string[];
  clusterVersion?: string;
  mfeVersion?: string;
}

const InternalRenderComponentPure = memo(
  function InternalRenderComponent({
    id,
    jsFile,
    cssFiles,
    jsResources,
    clusterVersion,
    mfeVersion,
    ...routeComponentProps
  }: InternalComponentProps) {
    // changes in the props here should be propagated to SearchAppDialog
    const dynamicComponentProps = useGetDynamicComponentProps(routeComponentProps);

    useEffect(() => {
      observeMicroFrontEnd(id, mfeVersion);
    }, [id, mfeVersion]); // RUM should only start a new view when the MFE and/or version changes

    const { applyCacheBustingToUrl } = useGetApplyCacheBustingToUrl();

    // will never happen
    if (!dynamicComponentProps) {
      return null;
    }

    if (id === PORTAL_APP) {
      return <PortalWrapper {...dynamicComponentProps} />;
    }

    const jsFileWithVersion = applyCacheBustingToUrl(jsFile, clusterVersion, mfeVersion);
    const cssFilesWithVersion = cssFiles?.map((url) => applyCacheBustingToUrl(url, clusterVersion, mfeVersion));
    const jsResourcesWithVersion = jsResources?.map((url) => applyCacheBustingToUrl(url, clusterVersion, mfeVersion));
    const observability = observabilityForMicroFrontEnd(id);
    const { commandBus, localEventBus } = eventDrivenBusesForMFE();

    return (
      <DynamicComponent
        jsFile={jsFileWithVersion}
        cssFiles={cssFilesWithVersion}
        jsResources={jsResourcesWithVersion}
        observability={observability}
        localEventBus={localEventBus}
        commandBus={commandBus}
        {...dynamicComponentProps}
      />
    );
  },
  getPropsAreEqual((prevValue, nextValue) => isEqual(prevValue, nextValue))
);

enum ErrorType {
  NOT_FOUND = "not_found",
  GATEWAY_TIMEOUT = "gateway_timeout",
  SERVER_ERROR = "server_error",
}

export interface ProductShellErrorComponentProps {
  appState: ProductShellState;
  errorMessage: string | undefined;
  serviceError: Error | undefined;
}

const ErrorHandler = ({ appState, errorMessage, serviceError }: ProductShellErrorComponentProps) => {
  const location = useLocation();
  if (serviceError) {
    return <ErrorPage id="product-shell-error-state" />;
  }

  switch (getErrorCode(appState)) {
    case ErrorType.NOT_FOUND:
      if (!location.key) {
        return <ErrorNotFound />;
      }
      return null;
    case ErrorType.GATEWAY_TIMEOUT:
    case ErrorType.SERVER_ERROR:
      return <ErrorPage message={errorMessage} />;
    default:
      return null;
  }
};

const ProductShellRouter: FC<ProductShellRouterProps> = ({ modules }) => {
  const { appState, serviceError, serviceConfig } = useProductShellContext();
  const { menu } = useMenuContext();
  const defaultRoute = useDefaultRoute();
  const areOnlyDialogMenus =
    menu?.groupedMenus.flatMap((group) => group.menuItems).filter(filterOutDialogMenus).length === 0;
  const productShellStateType = getAppStateType(appState);
  const errorMessage = getErrorMessage(appState);
  const { isModuleFederationEnabled } = useIsModuleFederationEnabled();

  useHandleDialogRoutes();

  return (
    <>
      <LoadingOverlay isVisible={productShellStateType === "loading"} />
      <ErrorHandler appState={appState} errorMessage={errorMessage} serviceError={serviceError} />
      <Switch>
        {modules.map((m) => (
          <Route
            key={m.id}
            path={m.routes}
            render={(routerProps) => (
              <InternalRenderComponentPure
                id={m.id}
                jsFile={m.jsFile}
                cssFiles={m.cssFiles}
                jsResources={m.jsResources}
                clusterVersion={serviceConfig?.cacheBustingSuffix}
                mfeVersion={m.version}
                {...routerProps}
              />
            )}
          />
        ))}
        {isModuleFederationEnabled && (
          <Route
            path="/apperangePageFederated"
            render={(routerProps) => {
              return (
                <InnerFederatedComponentPure
                  id={"federated-apperance-page"}
                  scope={dynamicComponentData.scope}
                  remoteModule={dynamicComponentData.module}
                  remoteEntry={dynamicComponentData.url}
                  version={dynamicComponentData.version}
                  {...routerProps}
                />
              );
            }}
          />
        )}
        {/* redirect to first available route from / or landing page */}
        <Route
          exact
          path="/"
          render={({ location }) => {
            if (location.hash && location.pathname === "/") {
              const url = customDashboardsHashUrlToUrl(location.hash);
              if (url !== location.hash) {
                return <Redirect to={url} />;
              }
            }
            return areOnlyDialogMenus ? <LandingPageNoMenu /> : <Redirect to={defaultRoute} />;
          }}
        />
        {/* default route with error page or landing page if areOnlyDialogMenus */}
        <Route path="*" render={() => (areOnlyDialogMenus ? <LandingPageNoMenu /> : <ErrorNotFound />)} />
      </Switch>
    </>
  );
};

export default ProductShellRouter;
